const getQueryParam = key => {
  const params = new URLSearchParams(window.location.search);
  const findQuery = params.get(key);
  return findQuery;
};

const getCookie = cookieName => {
  let cookieValue = null;
  if (document.cookie) {
    const array = document.cookie.split(escape(cookieName) + '=');
    if (array.length >= 2) {
      const arraySub = array[1].split(';');
      cookieValue = unescape(arraySub[0]);
    }
  }
  return cookieValue;
};

const removeCookie = async () => {
  const existCookie = getCookie('CLS-EVT-TG');
  if (!existCookie) {
    return;
  }

  try {
    await fetch(`/new/v0/api/benefit/coupon/${existCookie}/set_cookie/delete`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify({}),
    });
  } catch (error) {
    console.error(error);
  }
};

// 1. 이벤트 코드를 매칭해서 쿠키를 생성한다.
const setEventCookieInitialize = async userId => {
  const existCookie = getCookie('CLS-EVT-TG');
  if (existCookie) {
    // 쿠키가 이미 있을 경우 적립금 지급 대상자 확인 바로 가기
    return true;
  }

  const eventCouponCode = getQueryParam('coupon_code');
  // eventCodeKey 쿼리 파람이 없을 경우 리턴한다.
  if (!eventCouponCode) {
    return false;
  }

  try {
    const request = await fetch(`/new/v0/api/benefit/coupon/${eventCouponCode}/set_cookie`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify({}),
    });

    if (request.status !== 200) {
      return false;
    }

    if (!userId) {
      return false;
    }

    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

// 2. 이벤트 대상자인지를 조회한다.
const checkBenefitHolder = async userId => {
  if (!userId) {
    return false;
  }

  const existCookie = getCookie('CLS-EVT-TG');
  // 쿠키가 없을 경우 리턴한다.
  if (!existCookie) {
    return false;
  }

  try {
    // const request = await fetch('/v0/api/benefit/holder/{{@$user->id}}?benefit_id={id}')
    const request = await fetch(`/new/v0/api/benefit/holder/${userId}`, {
      credentials: 'include',
    });

    if (request.status !== 200) {
      // 대상자가 없을 경우에 에러 발생 - 무시해도 ok
      return false;
    }

    const result = await request.json();
    const isPaid = result.paid_status;

    // 이미 지급된 이력이 있다면 기존 쿠키를 지운다.
    if (isPaid) {
      return false;
      // return removeCookie();
    }

    const toDay = new Date();
    const expected_repay_date = new Date(result.expected_repay_date);

    if (expected_repay_date > toDay) {
      return false;
    }

    takeBenefitToUser(userId);
  } catch (error) {
    console.error(error);
    return false;
  }
};

function setZeroNumFn(num) {
  if (Number(num) < 10) {
    return '0' + num;
  }
  return num;
}

// 3. 적립금 지급 - 레거시 기존 기능 사용
const takeBenefitToUser = async userId => {
  // 적립금만료일은 지급 후 1주일
  const useLimitDate = new Date(new Date().setDate(new Date().getDate() + 7));
  const expiration_date = `${useLimitDate.getFullYear()}-${setZeroNumFn(
    useLimitDate.getMonth() + 1,
  )}-${setZeroNumFn(useLimitDate.getDate())}`;

  const typeKey = 'charlie_kakao';
  const comment = '새출발 지원금';

  try {
    // TODO: 지급받은 후 1주일 설정
    const payload = new FormData();
    payload.append('user_id', userId);
    payload.append('point_type', 'A');
    payload.append('type_key', typeKey);
    payload.append('type', 'admin');
    payload.append('type_id', 11016);
    payload.append('point', 10000);
    payload.append('expiration_date', expiration_date);
    payload.append('comment', comment);

    const xhr = new XMLHttpRequest();
    xhr.open('POST', 'https://api2.enfit.net/api/v3/point', true);
    xhr.setRequestHeader('Authorization', `Bearer ${process.env.NEXT_PUBLIC_DEFAULT_TOKEN}`);
    xhr.onload = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) {
        return;
      }
      if (xhr.status === 200 || xhr.status === 201) {
        takeBenefitHolder(userId);
      } else {
        console.warn(xhr.status + ': Data fetching, Error!');
        return false;
      }
    };
    xhr.send(payload);
  } catch (error) {
    console.error(error);
  }
};

// 4. 적립금 지급이 되었다면 대상자리스트에서 받았음을 체크한다.
const takeBenefitHolder = async userId => {
  if (!userId) {
    return false;
  }

  const existCookie = getCookie('CLS-EVT-TG');
  if (!existCookie) {
    return false;
  }

  try {
    // const request = await fetch('/new/v0/api/benefit/holder/{{@$user->id}}/take?benefit_id={id}')
    await fetch(`/new/v0/api/benefit/holder/${userId}/take`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify({}),
    });

    alert('회원님을 위한 배움지원금 10,000원이 지급되었습니다.');

    sendPushForUser(userId);
  } catch (error) {
    console.log(error);
  }
};

// 5. 푸쉬
async function sendPushForUser(userId) {
  try {
    const payload = new FormData();
    payload.append('user_id', userId);
    payload.append('title', '새출발 지원금이 도착했어요! 💵');
    payload.append('body', '회원님을 위한 배움지원금 10,000원이 지급되었습니다.');
    payload.append(
      'push_url',
      'https://www.classu.co.kr/new/user/point?coupon_code=af7cd5b6-bace-473d-8e80-86f6527c5309',
    );

    const xhr = new XMLHttpRequest();
    xhr.open('POST', 'https://api2.enfit.net/api/v3/send/push', true);
    xhr.setRequestHeader('Authorization', `Bearer ${process.env.NEXT_PUBLIC_DEFAULT_TOKEN}`);
    xhr.onload = function () {
      if (xhr.readyState !== XMLHttpRequest.DONE) {
        return;
      }
      if (xhr.status === 200 || xhr.status === 201) {
        // const result = JSON.parse(xhr.responseText);
      } else {
        console.warn(xhr.status + ': Data fetching, Error!');
      }
    };
    xhr.send(payload);
  } catch (error) {
    console.error(error);
  }
}

export const benefitHolder = {
  removeCookie,
  setEventCookieInitialize,
  checkBenefitHolder,
};
