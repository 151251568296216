import { useState, useEffect, Fragment } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import styled from 'styled-components';
// utils
import deviceChecker from 'lib/utils/deviceChecker';
// styles
import * as typography from 'cds/typography';
import { palette } from 'cds/styles';

/**
 * 메인 기획전 리스트
 * @param {object} props
 * @param {object[]} props.list
 */
const Exhibition = ({ list }) => {
  const router = useRouter();

  const [isIpadApp, setIsIpadApp] = useState(false);

  const onMoveCommunity = () => {
    router.push('/community');
  };

  // ipadApp check
  useEffect(() => {
    const device = deviceChecker();
    setIsIpadApp(device.iPadApp());
  }, []);

  const Items = list.map(v => {
    if (!isIpadApp) {
      return (
        <Item key={v.title}>
          <Link href={v.address} passHref>
            <Box>
              <Thumb url={v.icon2} />
              <Title>{v.title}</Title>
            </Box>
          </Link>
        </Item>
      );
    }

    const ignore =
      v.address.indexOf('/event') > -1 ||
      v.address.indexOf('/bookmark') > -1 ||
      v.address.indexOf('/awards') > -1 ||
      v.address.indexOf('/external') > -1;

    if (ignore) {
      return <Fragment key={v.title}></Fragment>;
    }

    return (
      <Item key={v.title}>
        <Link href={v.address} passHref>
          <Box>
            <Thumb url={v.icon2} />
            <Title>{v.title}</Title>
          </Box>
        </Link>
      </Item>
    );
  });

  return (
    <List>
      {Items}
      {Items.length > 0 && (
        <Item onClick={onMoveCommunity}>
          <Box as="button">
            <Thumb
              url={`${process.env.NEXT_PUBLIC_ASSET_PREFIX}/uploads/original/0fOCiBI7lCp3X4R6.png`}
            />
            <Title>커뮤니티</Title>
          </Box>
        </Item>
      )}
    </List>
  );
};

const List = styled.ul`
  overflow-x: auto;
  overflow-y: hidden;
  min-height: 54px;
  padding: 0 16px 0;
  white-space: nowrap;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
    width: 0;
    background-color: transparent;
  }
`;

const Item = styled.li`
  display: inline-block;
  min-width: 60px;
  font-size: 0;
  vertical-align: top;

  & ~ & {
    margin-left: 24px;
  }
`;

const Box = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: auto;
`;

const Thumb = styled.div`
  overflow: hidden;
  flex: 0 auto;
  width: 44px;
  height: 44px;
  margin: 0 auto 4px;
  background-image: ${({ url }) => `url(${url})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
`;

const Title = styled.p`
  ${typography.caption3_medium}
  color: ${({ color }) => (color ? color : palette.grey080)};
  white-space: initial;
  word-break: keep-all;
  text-align: center;
`;

export default Exhibition;
