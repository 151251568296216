import { useRef } from 'react';
// components
import RegisteredItem from 'components/molecules/class/card/RegisteredItem';
import HorizontalScrollSlider, { Slide } from 'components/molecules/common/HorizontalScrollSlider';

/**
 * 수강 중인 클래스
 * @param {object} param
 * @param {object[]} param.list
 * @param {object[]} param.sliderPerView
 */
const RegisteredListSlider = ({ list = [], slidePerView }) => {
  const sliderRef = useRef(null);

  const Items = list.map(v => {
    return (
      <Slide key={v.class_id} slidePerView={slidePerView}>
        <RegisteredItem data={v} />
      </Slide>
    );
  });

  return <HorizontalScrollSlider ref={sliderRef} list={list} item={Items} />;
};

export default RegisteredListSlider;
