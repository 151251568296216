import styled from 'styled-components';
import React, { useState, useEffect, useCallback, useRef } from 'react';
// utils
import { getKoreaDate } from 'lib/utils/format/time';

// TODO 끝에서 시작지점으로 루틴 제거 옵셔널 추가
// TODO 최적화 여지 확인하기
/**
 * 스와이프 Hook
 *
 * @param {object} props
 * @param {any[]} props.items
 * @param {number} props.startIndex
 * @param {Function} props.prevCallback
 * @param {Function} props.nextCallback
 *
 * @example
 * const {
 *   currentIndex,
 *   currentList,
 *   Slider,
 *   onPrev,
 *   onNext
 * } = useSwipe({
 *   items: [1, 2, 3, ... ],
 *   startIndex: 0,
 *   () => { ... },
 *   () => { ... } }
 * );
 */
const useSwipe = ({ items, startIndex = 0, prevCallback, nextCallback }) => {
  const [list, setList] = useState([]);
  const [idx, setIdx] = useState(1);

  const slider = useRef(null);
  const currTouchX = useRef(0);
  const prevTouchX = useRef(0);
  const timeInterval = useRef(0);
  const nextIdx = useRef(1);
  const itemsLength = useRef(items.length);
  const mode = useRef('LOCK');

  const unify = e => (e.changedTouches ? e.changedTouches[0] : e);

  const onAllowTouch = e => {
    if (getKoreaDate().getTime() - timeInterval.current > 100) {
      e.preventDefault();
    }
  };

  const onMouseMove = useCallback(e => {
    if (e.cancelable) {
      e.preventDefault();
    }

    currTouchX.current = `${Math.round(unify(e).clientX - prevTouchX.current)}`;
    slider.current.style.transform = `translateX(calc(${currTouchX.current}px + ${nextIdx.current} * -100%))`;
  }, []);

  const onMouseUp = useCallback(
    e => {
      let diffX = unify(e).clientX - prevTouchX.current;
      let signX = Math.sign(diffX);
      let frame = +((signX * diffX) / slider.current.clientWidth).toFixed(2);

      if (Math.abs(diffX) > slider.current.clientWidth / 30) {
        if (signX < 0) {
          mode.current = 'NEXT';
          nextIdx.current += 1;
        } else {
          mode.current = 'PREV';
          nextIdx.current -= 1;
        }

        frame = 1 - frame;
      } else {
        mode.current = 'LOCK';
      }

      prevTouchX.current = 0;
      currTouchX.current = 0;

      slider.current.style.transform = `translateX(calc(${currTouchX.current}px + ${nextIdx.current} * -100%))`;
      slider.current.style.transition = `transform calc((${frame}) * .2s) ease-out`;

      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);

      document.removeEventListener('touchmove', onMouseMove);
      document.removeEventListener('touchend', onMouseUp);
    },
    [onMouseMove],
  );

  const onMouseDown = useCallback(
    e => {
      if (!e.type === 'touchstart') {
        e.preventDefault();
      }

      timeInterval.current = new Date().getTime();
      prevTouchX.current = unify(e).clientX;
      slider.current.style.transition = ``;

      document.addEventListener('mousemove', onMouseMove, { passive: false });
      document.addEventListener('mouseup', onMouseUp);

      document.addEventListener('touchmove', onMouseMove, { passive: false });
      document.addEventListener('touchend', onMouseUp);
    },
    [onMouseMove, onMouseUp],
  );

  const onTransitionEnd = useCallback(() => {
    if (mode.current === 'NEXT') {
      setList(v => {
        const first = v.slice(0, 1);
        const other = v.slice(1);
        return [...other, ...first];
      });
      setIdx(prev =>
        prev + 1 > itemsLength.current ? (prev % itemsLength.current) + 1 : prev + 1,
      );
      nextCallback && nextCallback();
    } else if (mode.current === 'PREV') {
      setList(v => {
        const last = v.slice(-1);
        const other = v.slice(0, -1);
        return [...last, ...other];
      });
      setIdx(prev => (prev - 1 === 0 ? itemsLength.current : prev - 1));
      prevCallback && prevCallback();
    }

    slider.current.style.transform = `translateX(-100%)`;
    slider.current.style.transition = ``;

    nextIdx.current = 1;
  }, [prevCallback, nextCallback]);

  const onPrev = useCallback(() => {
    mode.current = 'PREV';
    onTransitionEnd();
  }, [onTransitionEnd]);

  const onNext = useCallback(() => {
    mode.current = 'NEXT';
    onTransitionEnd();
  }, [onTransitionEnd]);

  /** 아이템 리스트 변경 */
  useEffect(() => {
    if (items.length === 1) {
      setList(items);
      return;
    }

    if (items.length === 2) {
      if (startIndex === 1) {
        setList([...items, ...items]);
        return;
      }

      setList([...items, ...items].reverse());
      return;
    }

    setList(() => {
      const last = startIndex === 0 ? items.slice(-1) : items.slice(startIndex - 1);
      const other = startIndex === 0 ? items.slice(0, -1) : items.slice(0, startIndex - 1);
      return [...last, ...other];
    });
  }, [items, startIndex]);

  /* eslint-disable */
  /** Slider 초기 위치 */
  useEffect(() => {
    if (!slider.current) {
      return;
    }

    if (items.length === 1) {
      slider.current.style.transform = `translateX(0%)`;
    } else {
      slider.current.style.transform = `translateX(-100%)`;
    }
  }, [items.length, slider.current]);
  /* eslint-enable */

  /* eslint-disable */
  /** 이벤트 등록 */
  useEffect(() => {
    if (items.length === 1) {
      return;
    }

    const target = slider.current;
    if (!target) {
      return;
    }

    target.addEventListener('touchstart', onMouseDown, { passive: false });
    target.addEventListener('mousedown', onMouseDown, { passive: false });
    target.addEventListener('transitionend', onTransitionEnd);
    target.addEventListener('click', onAllowTouch);

    return () => {
      target.removeEventListener('touchstart', onMouseDown, { passive: false });
      target.removeEventListener('mousedown', onMouseDown), { passive: false };
      target.removeEventListener('transitionend', onTransitionEnd);
      target.removeEventListener('click', onAllowTouch);
    };
  }, [onMouseDown, onTransitionEnd, items.length, slider.current]);
  /* eslint-enable */

  useEffect(() => (itemsLength.current = items.length), [items.length]);

  // eslint-disable-next-line react/display-name
  const Slider = React.memo(({ children }) => <List ref={slider}>{children}</List>, []);

  return {
    currentIndex: idx,
    currentList: list,
    Slider,
    onPrev,
    onNext,
  };
};

const List = styled.ul`
  width: 100%;
  height: 100%;
  white-space: nowrap;
  cursor: pointer;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
    width: 0;
    background-color: transparent;
  }
`;

export default useSwipe;
