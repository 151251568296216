/**
 * 배열 생성
 * @param {number} start
 * @param {number} end
 * @returns object[]
 */
export const range = (start, end) => {
  const ans = [];
  for (let i = start; i <= end; i++) {
    ans.push(i);
  }
  return ans;
};

/**
 * 배열 원소를 랜덤으로 섞고 새로운 배열을 반환한다.
 * @param {object} arr
 * @returns {object[]}
 */
export const getShuffledArray = arr => {
  const newArr = arr.slice();
  for (let i = newArr.length - 1; i > 0; i--) {
    const rand = Math.floor(Math.random() * (i + 1));
    [newArr[i], newArr[rand]] = [newArr[rand], newArr[i]];
  }
  return newArr;
};
