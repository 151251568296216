import { useState, useEffect, useCallback } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import styled from 'styled-components';
// components
import PopularityTagList from 'components/organisms/main/PopularityTagList';
import Exhibition from 'components/organisms/main/Exhibition';
import Banner from 'components/organisms/main/Banner';
import ClassGridList from 'components/organisms/main/ClassGridList';
import Category from 'components/organisms/main/Category';
import ClassListSlider from 'components/molecules/class/ClassListSlider';
import RegisteredListSlider from 'components/molecules/class/RegisteredListSlider';
import MainLayout from 'cds/layout/Main';
import Layout from 'components/layout';
// slices
import {
  getEventClassList,
  getKeywordClassList,
  getMainClassList,
  getServiceInfo,
} from 'slices/main.thunk';
import { getRecentClassList } from 'slices/user.thunk';
// hooks
import { useSelector, useDispatch } from 'hooks/common/useStore';
import useModal from 'hooks/common/useModal';
// import useCommonBridge from 'hooks/bridge/useCommonBridge';
// utils
import deviceChecker from 'lib/utils/deviceChecker';
import { isEmptyObject } from 'lib/utils/helper';
import { formatToPrice } from 'lib/utils/format/number';
// styles
import * as typography from 'cds/typography';
import { palette, mediaValue, mediaQuery } from 'cds/styles';
import { isInBrowser } from 'lib/utils/condition';
import { useDataMining } from 'hooks/dataMining/useDataMining';
import { setFamousClassKeyword } from 'slices/dataMining';
import { benefitHolder } from 'lib/benefit/holder';

/**
 * 랜덤 숫자를 리턴합니다.
 * @param {object} props
 * @param {number} props.max
 * @returns
 */
const getRandomNumber = ({ max }) => {
  return Math.floor(Math.random() * max);
};

/**
 * 메인 페이지
 */
const MainPage = () => {
  const {
    userId,
    keywordInfo,
    marketingLowPriceInfo,
    famousClassInfo,
    recentClassInfo,
    recomClassInfo,
  } = useSelector(({ search, main, user }) => ({
    keywordInfo: search.keywordRankList,
    recentClassInfo: user.recentClassList,
    famousClassInfo: main.keywordRecommend.recom_list,
    marketingLowPriceInfo: main.eventRecommend.recom_list,
    recomClassInfo: main.recommendClassList,
    userId: user.info.id,
  }));

  const [subscribeList, setSubScribeList] = useState({});

  const { removeCookie, setEventCookieInitialize, checkBenefitHolder } = benefitHolder;

  const [isRender, setRender] = useState(false);

  useEffect(() => {
    setRender(true);
  }, []);

  const setBenefit = useCallback(async () => {
    const initialize = await setEventCookieInitialize(userId);
    if (!initialize) {
      return;
    }

    const checkTarget = await checkBenefitHolder(userId);
    if (!checkTarget) {
      return;
    }

    removeCookie();
  }, [checkBenefitHolder, removeCookie, setEventCookieInitialize, userId]);

  useEffect(() => {
    if (!isRender) {
      return;
    }

    setBenefit();
  }, [isRender, setBenefit, userId]);

  useEffect(() => {
    if (!userId) {
      setSubScribeList(prev => ({
        ...prev,
        keywordInfo,
        marketingLowPriceInfo,
        famousClassInfo,

        recomClassInfo,
      }));
      return;
    }
    setSubScribeList(prev => ({
      ...prev,
      keywordInfo,
      marketingLowPriceInfo,
      famousClassInfo,
      recentClassInfo,
      recomClassInfo,
    }));
  }, [
    keywordInfo,
    marketingLowPriceInfo,
    famousClassInfo,
    recentClassInfo,
    recomClassInfo,
    userId,
  ]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  isInBrowser() && useDataMining({ subscribeList });

  const router = useRouter();

  const dispatch = useDispatch();

  const [planEvent, bannerList] = useSelector(state => [state.event.eventList, state.event.banner]);
  const [userInfo, registeredClassList, recentClassList] = useSelector(state => [
    state.user.info,
    state.user.classList.registered,
    state.user.recentClassList,
  ]);
  const [
    serviceInfo,
    recommendClassList,
    eventRecommendClassList,
    keyword,
    keywordType,
    keywordRecommendClassList,
  ] = useSelector(state => [
    state.main.serviceInfo,
    state.main.recommendClassList,
    state.main.eventRecommend.recom_list,
    state.main.keywordRecommend.keyword,
    state.main.keywordRecommend.keyword_type,
    state.main.keywordRecommend.recom_list,
  ]);

  const [isIpadApp, setIsIpadApp] = useState(false);
  const [dealClassList, setDealClassList] = useState([]);
  const [recomnClass, setRecomnClass] = useState([]);

  const { initModal } = useModal();
  // const { onOutLinkForBrowser } = useCommonBridge();

  // const onOpenOutBrowser = () => {
  //   const url = `${process.env.NEXT_PUBLIC_DEFAULT_HOST}/app_search`;
  //   onOutLinkForBrowser({ url });
  // };

  /** ipadApp 여부 확인 */
  useEffect(() => {
    const device = deviceChecker();
    setIsIpadApp(device.iPadApp());
  }, []);

  /** 초저가 클래스 호출 */
  useEffect(() => {
    if (isIpadApp) {
      return;
    }

    if (!userInfo.isLoaded || eventRecommendClassList.length) {
      return;
    }

    dispatch(getEventClassList({ userId: userInfo.id }));
  }, [dispatch, userInfo, eventRecommendClassList.length, isIpadApp]);

  /** 키워드 클래스 호출 */
  useEffect(() => {
    if (isIpadApp) {
      return;
    }

    if (userInfo.isLoaded && !keywordRecommendClassList.length) {
      dispatch(getKeywordClassList({ userId: userInfo.id }));
    }
  }, [dispatch, userInfo, keywordRecommendClassList.length, isIpadApp]);

  /** 최근 본 클래스 호출 */
  useEffect(() => {
    if (isIpadApp) {
      return;
    }

    if (userInfo.isLoaded && userInfo.id) {
      dispatch(getRecentClassList({ userId: userInfo.id }));
    }
  }, [dispatch, isIpadApp, userInfo]);

  /** 추천 클래스 호출 */
  useEffect(() => {
    if (isIpadApp) {
      return;
    }

    if (userInfo.isLoaded && !recommendClassList.length) {
      dispatch(getMainClassList({ userId: userInfo.id }));
    }
  }, [dispatch, userInfo, recommendClassList.length, isIpadApp]);

  /** 초저가 클래스에 배너 무조건 0번 인덱스에 넣기 */
  useEffect(() => {
    if (!eventRecommendClassList.length) {
      return;
    }

    if (!bannerList.length) {
      return setDealClassList(eventRecommendClassList);
    }

    let n = getRandomNumber({ max: bannerList.length });

    while (
      /event/.test(bannerList[n].link) === false ||
      /event\/plan\/1/.test(bannerList[n].link)
    ) {
      n = getRandomNumber({ max: bannerList.length });
    }

    const banner = Object.assign({}, bannerList[n]);
    banner.link = banner.link.replace(
      /https?:\/\/\www\.classu\.co\.kr/gim,
      process.env.NEXT_PUBLIC_DEFAULT_HOST,
    );

    const newList = [banner, ...eventRecommendClassList];

    setDealClassList(newList);
  }, [bannerList, eventRecommendClassList]);

  /** 추천 클래스에 배너 랜덤 인덱스에 넣기 */
  useEffect(() => {
    if (!recommendClassList.length) {
      return;
    }

    if (!bannerList.length) {
      return setRecomnClass(recommendClassList);
    }

    const n = getRandomNumber({ max: recommendClassList.length + 1 });

    const newList = [...recommendClassList];

    const banner = Object.assign({}, bannerList[1]);
    banner.link = banner.link.replace(
      /https?:\/\/\www\.classu\.co\.kr/gim,
      process.env.NEXT_PUBLIC_DEFAULT_HOST,
    );

    newList.splice(n, 1, banner);

    setRecomnClass(newList);
  }, [bannerList, isIpadApp, recommendClassList]);

  /** 서비스 정보 호출 */
  useEffect(() => {
    if (!router.isReady) {
      return;
    }

    if (isIpadApp) {
      return;
    }

    dispatch(getServiceInfo());
  }, [dispatch, isIpadApp, router.isReady]);

  /** 추가정보 입력 모달 */
  useEffect(() => {
    if (!userInfo.isShowRequirementModal) {
      return;
    }
    initModal({ name: 'RequirementInfo' });
  }, [userInfo, initModal]);

  /** 인기 클래스 키워드 저장 */
  useEffect(() => {
    dispatch(setFamousClassKeyword(`${keyword}`));
  }, [dispatch, keyword, keywordInfo]);

  return (
    <>
      <Wrapper>
        {!isIpadApp && (
          <TagWrapper>
            <PopularityTagList />
          </TagWrapper>
        )}

        {!isIpadApp && <SafeArea />}

        <ExhibitionWrapper>
          <Exhibition list={planEvent} />
        </ExhibitionWrapper>

        {registeredClassList.length ? (
          <RegisteredWrapper>
            <div>
              <Title>수강 중인 클래스</Title>

              <RegisteredListSlider
                list={registeredClassList}
                slidePerView={[
                  {
                    break: 'default',
                    view: 2.04,
                  },
                  {
                    break: mediaValue.small,
                    view: 3.04,
                  },
                  {
                    break: mediaValue.large,
                    view: 3,
                  },
                  {
                    break: 1480,
                    view: 4,
                  },
                  {
                    break: 1900,
                    view: 5,
                  },
                ]}
              />
            </div>
          </RegisteredWrapper>
        ) : (
          <Divide />
        )}

        {/* {isIpadApp && (
          <FindClass>
            <FindClassButton type="button" color={'Red'} onClick={onOpenOutBrowser}>
              더 많은 클래스를 찾아볼까요?
            </FindClassButton>
          </FindClass>
        )} */}

        {!isIpadApp && (
          <ContentWrapper>
            {!!dealClassList.length && (
              <>
                {userInfo.id && <Title>{userInfo.name}님을 위한 오늘의 초저가 클래스</Title>}
                {!userInfo.id && <Title>오늘의 초저가 클래스</Title>}

                <ClassListSlider
                  list={dealClassList}
                  showSalePer
                  showPrice
                  slidePerView={[
                    {
                      break: 'default',
                      view: 1.4,
                    },
                    {
                      break: mediaValue.small,
                      view: 2.4,
                    },
                    {
                      break: mediaValue.large,
                      view: 3,
                    },
                    {
                      break: 1480,
                      view: 4,
                    },
                    {
                      break: 1900,
                      view: 5,
                    },
                  ]}
                />
              </>
            )}

            <BannerWrapper>
              <Banner />
            </BannerWrapper>

            {!isEmptyObject(serviceInfo) && (
              <ServiceInfoBanner>
                <h2>커뮤니티형 온라인 강의 플랫폼</h2>
                <p>
                  <strong>{formatToPrice(serviceInfo.total_curriculum_cnt)}개</strong> 강의에서{' '}
                  <strong>{formatToPrice(serviceInfo.total_community_cnt)}개</strong>의 수강 활동을
                  통해 배움을 즐겁게 만들어 가고 있습니다.
                </p>
              </ServiceInfoBanner>
            )}

            {keywordRecommendClassList.length > 0 && (
              <>
                {keywordType === 'user_history' && (
                  <Title>
                    <span>
                      검색하신{' '}
                      <Link href={`/search?keyword=${keyword}`}>
                        <a>{keyword}</a>
                      </Link>
                      에
                    </span>
                    <span>관련된 인기 클래스예요.</span>
                  </Title>
                )}
                {keywordType === 'search_rank' && (
                  <Title>
                    <span>
                      {`인기검색어 `}
                      <Link href={`/search?keyword=${keyword}`}>
                        <a>{keyword}</a>
                      </Link>
                      에
                    </span>
                    <span>관련된 인기 클래스예요.</span>
                  </Title>
                )}

                <ClassListSlider
                  list={keywordRecommendClassList}
                  slidePerView={[
                    {
                      break: 'default',
                      view: 2.04,
                    },
                    {
                      break: mediaValue.small,
                      view: 3.04,
                    },
                    {
                      break: mediaValue.large,
                      view: 3,
                    },
                    {
                      break: 1480,
                      view: 4,
                    },
                    {
                      break: 1900,
                      view: 5,
                    },
                  ]}
                />
                <Divide />
              </>
            )}

            {userInfo.id && recentClassList.length > 0 && (
              <>
                <Title>최근 본 클래스</Title>
                <ClassListSlider
                  list={recentClassList}
                  slidePerView={[
                    {
                      break: 'default',
                      view: 2.04,
                    },
                    {
                      break: mediaValue.small,
                      view: 3.04,
                    },
                    {
                      break: mediaValue.large,
                      view: 3,
                    },
                    {
                      break: 1480,
                      view: 4,
                    },
                    {
                      break: 1900,
                      view: 5,
                    },
                  ]}
                />
                <Divide />
              </>
            )}

            {userInfo.id && <Title>{userInfo.name}님을 위한 추천 클래스</Title>}
            {!userInfo.id && <Title>추천 클래스</Title>}
            <ClassGridList
              list={recomnClass}
              slidePerView={[
                {
                  break: 'default',
                  view: 1,
                },
                {
                  break: mediaValue.small,
                  view: 2,
                },
                {
                  break: mediaValue.medium,
                  view: 3,
                },
                {
                  break: 1480,
                  view: 4,
                },
                {
                  break: 1900,
                  view: 5,
                },
              ]}
            />
            <Divide />

            <CategoryListWrapper>
              <Title>찾고 있는 클래스가 있나요?</Title>
              <Category />
            </CategoryListWrapper>
          </ContentWrapper>
        )}
      </Wrapper>
    </>
  );
};

MainPage.getLayout = function getLayout(page) {
  return <Layout>{page}</Layout>;
};

const Wrapper = styled(MainLayout)`
  width: 100%;
  padding-bottom: 64px;

  ${mediaQuery.xlarge} {
    padding-bottom: 100px;
  }
`;

const TagWrapper = styled.div`
  display: none;
  background-color: ${palette.white};

  ${mediaQuery.large} {
    display: block;
  }
`;

const ContentWrapper = styled.div`
  margin-top: 24px;

  ${mediaQuery.large} {
    width: 1800px;
    max-width: 100%;
    padding: 0 32px;
    margin: 48px auto;
  }
  ${mediaQuery.xlarge} {
    padding: 0 72px;
  }
`;

const SafeArea = styled.div`
  height: 56px;

  ${mediaQuery.large} {
    display: none;
  }
`;

const Title = styled.h2`
  padding: 0 16px;
  margin: 0 0 20px;
  ${typography.subtitle3_bold}
  color: ${palette.grey080};
  transition: font-size 0.2s ease;

  span {
    display: block;
  }

  a {
    color: ${palette.blue070};
  }

  ${mediaQuery.large} {
    font-size: 18px;
    padding: 0;
    margin: 0 0 16px;

    span {
      display: inline-block;

      & ~ span {
        margin-left: 6px;
      }
    }
  }
`;

const Divide = styled.hr`
  height: 10px;
  margin: 32px 0 24px;
  border-style: solid;
  border-width: 1px 0 0 0;
  border-color: ${palette.border.b1};
  background-color: ${palette.background.bg2};

  ${mediaQuery.large} {
    height: 1px;
    margin: 48px 0;
    background-color: ${palette.background.grey030};
  }

  &:last-of-type {
    ${mediaQuery.large} {
      display: none;
    }
  }
`;

const ExhibitionWrapper = styled.div`
  margin: 20px 0;

  ${mediaQuery.large} {
    display: none;

    & + ${Divide} {
      display: none;
    }
  }
`;

const RegisteredWrapper = styled.div`
  background-color: ${palette.grey005};

  ${mediaQuery.large} {
    background-color: ${palette.grey010};
  }

  & > div {
    padding: 24px 0 32px;

    ${mediaQuery.large} {
      width: 1800px;
      max-width: 100%;
      padding: 24px 32px 32px;
      margin: 0 auto;
    }

    ${mediaQuery.xlarge} {
      padding: 24px 72px 32px;
    }
  }
`;

const BannerWrapper = styled.div`
  margin: 32px 0 24px 0;

  ${mediaQuery.large} {
    display: none;
  }
`;

const ServiceInfoBanner = styled.div`
  overflow: hidden;
  display: none;
  padding: 30px 24px;
  margin: 48px 0;
  border-radius: 8px;
  background-color: ${palette.grey010};
  text-align: center;

  ${mediaQuery.large} {
    display: block;
  }

  h2 {
    margin-bottom: 16px;
    color: ${palette.grey080};
    font-size: 26px;
    font-weight: 700;
    line-height: 36px;
  }

  p {
    color: ${palette.grey070};
    ${typography.body1_regular}
    word-break: keep-all;
  }

  strong {
    position: relative;
    color: ${palette.red040};
    ${typography.subtitle3_medium}

    &::after {
      display: block;
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      bottom: -2px;
      left: 0;
      background-color: currentColor;
    }
  }
`;

const CategoryListWrapper = styled.div`
  ${mediaQuery.large} {
    margin-top: 80px;
  }
`;

export default MainPage;
